<template>
     <div>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
                        <div class="card">
                            <div class="card-body table-responsive table-head">
                                <custom-alert v-if="displayAlert==true" :message="alertMessage" v-on:showedAlert="resetAlert" ></custom-alert>                                
                                <div class="row bor-bot pb-3">
                                    <div class="col-md-8 pl-0">
                                        <h1 class="m-0 text-dark pt-2 pb-2">{{ ($route.params.gid) ? 'Edit': 'Add' }} Group
                                            <router-link :to="`/admin/group`" class="ml-1 btn btn-secondary"> <i class="fa fa-arrow-left" aria-hidden="true"></i> Back to Groups</router-link>                                            
                                        </h1>
                                        
                                    </div>
                                    <div class="col-md-4">
                                        <div class="text-right pb-3">
                                            <ol class="breadcrumb float-sm-right">
                                                <li class="breadcrumb-item">
                                                    <a href="/admin/dashboard">Home</a>
                                                </li>
                                                <li class="breadcrumb-item">
                                                    <a href="/admin/group">Group</a>
                                                </li>
                                                <li class="breadcrumb-item"><Breadcrumbs /></li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                               
                                <b-form @submit="onSubmit" >
                                    <div class="row mt-3">                                         
                                        <div class="col-md-8 form-group">
                                            <label for="group_name" class="control-label">Group Name</label>
                                            <input type="text" class="form-control" id="group_name" name="group_name" v-model="form.group_name">
                                            <span class="text-sm text-danger">{{ errors.group_name }}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5 form-group">
                                            <label for="permissions">Permission</label>
                                            <b-form-checkbox-group
                                            v-model="form.permissions"
                                            :options="permissions" 
                                            stacked
                                            value-field="id"
                                            text-field="text"
                                            class="mb-3 form-check"></b-form-checkbox-group>
                                            <span class="text-sm text-danger">{{ errors.permission }}</span>
                                        </div>                                    
                                        <div class="col-md-5 form-group">
                                            <label for="tags">Tags</label>
                                            <b-form-checkbox-group
                                            v-model="form.tags"
                                            :options="tags"                                              
                                            stacked
                                            value-field="id"
                                            text-field="text"
                                            class="mb-3 form-check"></b-form-checkbox-group>                                           
                                            <span class="text-sm text-danger">{{ errors.tags }}</span>                                            
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-2">
                                            <b-button type="submit" :disabled="submitting" variant="primary" class="btn-blue">Submit</b-button>
                                        </div>
                                    </div>
                                </b-form>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>
     </div>
</template>

<script>
    import * as $ from "jquery"; 
    import moment from "moment";
    import axios from 'axios';
    import Select2 from "v-select2-component";
    import CustomAlert from "../../CustomAlert";

    const validateName = name => {
        if (name == null || name == '' || name.trim() == '') {
            return { valid: false, error: "This field is required" };
        }
        return { valid: true, error: null };
    };

    const validatecheckbox = chkbox => {
        if (chkbox == null || chkbox==0 || chkbox == '') {
            return { valid: false, error: "This field is required" };
        }
        return { valid: true, error: null };
    };

    export default {
        name: 'addGroup',
        components: { CustomAlert },
        data() {
            return {
                tags:[],
                permissions:[],
                form:{
                    group_name:'',
                }, 
                submitting: false,          
            }
        },
        methods: {
            getTags()
            {
                axios.get(this.basePath+'api/admin/group/tags',{ headers:this.adminHeaders })
                .then((res) => {
                    this.tags = res.data.data;                  
                })
                .catch((error) => {
                    console.log('Error:'+ error);               
                }).finally(() => {
                });
            },
            getPermission()
            {
                axios.get(this.basePath+'api/admin/group/permission',{ headers:this.adminHeaders })
                .then((res) => {
                    this.permissions = res.data.data;                  
                })
                .catch((error) => {
                    console.log('Error:'+ error);               
                }).finally(() => {
                });
            },
            validate() {
                this.errors = {}
                this.valid = false;
                const validgroupName = validateName(this.form.group_name);
                this.errors.group_name = validgroupName.error;

                if(validgroupName.valid)
                {
                    this.valid = true;
                }
                return this.valid;

            },
            onSubmit(event) { 
                this.submitting = true;
                event.preventDefault();               
                if (!this.validate()) {
                    this.submitting = false;
                    return;
                }
                let apiurl = (this.$route.params.gid) ? 'api/admin/group/edit/'+this.$route.params.gid: 'api/admin/group/store';
                axios.post(this.basePath+apiurl, this.form, { headers: this.adminHeaders })
                .then((res) => {
                    this.displayAlert = true, 
                    this.alertMessage = res.data.message;   
                    this.submitting = false;
                    this.form = {}
                    this.$router.push({path: '/admin/group'});                  
                })
                .catch((error) => {
                    console.log('Error:'+ error);               
                    return false;                
                }).finally(() => {
                    
                }); 
                this.errors = {};
            },
            getGroup() {
                axios.get(this.basePath+'api/admin/group/'+this.$route.params.gid, { headers: this.adminHeaders })
                .then((res) => {
                    this.form.group_name=res.data.data.name;  
                    this.form.permissions = res.data.data.permission.map(permission => ( permission.id));
                    this.form.tags = res.data.data.tags.map(tag => ( tag.id));  
                })
                .catch((error) => {
                    console.log('Error:'+ error);               
                    return false;                
                }).finally(() => {
                    
                }); 
            },
        },
        mounted() {
            if(this.$route.params.gid)
            {
                this.getGroup();                
            }
            this.getTags();
            this.getPermission();
        }
    }
</script>
<style>
.form-check .custom-control-label:before {
    background: transparent;
    position: relative;
    left: 0;
    bottom: 0;
    top: 0;
}
.form-check .custom-control-input:checked~.custom-control-label::before {
    background: transparent;
    border: 1px solid #ccc;
    box-shadow: none;
    position: relative;
    left: 0;
}
.form-check .custom-checkbox {
    padding-left: 0;
}
.form-check .custom-control.custom-checkbox {
    margin-bottom: 7px;
}
.form-check label:before
{
    box-shadow: none;
}
</style>